module.exports={
  "login_micro_url": "",
  "pwreset_micro_url": "",
  "redirect_to": "/",
  "password_reset_message": "/boss-portal/api/password-reset-message.txt",
  "tool_name": "BOSS PN",
  "organization": "Biarri Rail",
  "org_homepage": "http://biarrirail.com",
  "background_url": "/login.jpg"
}
