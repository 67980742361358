"use strict";

import "babel-polyfill";

/* global require zxcvbn */
var CONFIG = require("./config.json");

/*
 * @className AuthConnector
 * @description Manages the user login, registration and forgot password
 * interaction with the server.
 */
class AuthConnector {
  /**
   * @name constructor
   * @description Initializes a new UserConnector object
   */
  constructor() {
    // Create value & error stores
    this.errors = [];
    this.messages = [];

    // Store element references
    this.elements = {
      login: {
        email: document.getElementById("login-email"),
        password: document.getElementById("login-password"),
      },
      register: {
        firstname: document.getElementById("register-firstname"),
        lastname: document.getElementById("register-lastname"),
        email: document.getElementById("register-email"),
        phone: document.getElementById("register-phone"),
      },
      forgot: {
        email: document.getElementById("forgot-email"),
        hash: document.getElementById("forgot-hash"),
        password: document.getElementById("forgot-password"),
        password_check: document.getElementById("forgot-password-check"),
      },
    };

    // Build out field bindings based on element references
    for (var category of Object.keys(this.elements)) {
      for (var field of Object.keys(this.elements[category])) {
        this.setChangeHandlers(this.elements[category][field], category);
      }
    }

    // Store button references
    this.buttons = {
      login: document.getElementById("button-login"),
      register: document.getElementById("button-register"),
      forgot: document.getElementById("button-forgot"),
      reset: document.getElementById("button-reset"),
    };

    // Bind buttons
    this.bindLogin(this.buttons.login);
    this.bindForgot(this.buttons.forgot);
    this.bindReset(this.buttons.reset);
  }

  /**
   * @name bindLogin
   * @description Binds a login function to the butotn that sends the user email
   * and password to the server and if 200 is returned redirects the user to.
   * @param {string} email - User email to login with.
   * @param {string} password - User password to login with.
   */
  bindLogin(element) {
    element.onclick = () => {
      this.errors = [];
      this.messages = [];
      var username = this.elements.login.email.value.trim();
      var password = this.elements.login.password.value;
      let checks = [username.length > 0, password.length > 0];

      if (checks.indexOf(false) === -1) {
        try {
          fetch(CONFIG.login_micro_url + "/login/api/user-session", {
            method: "PUT",
            mode: "cors",
            body: JSON.stringify({ username: username, password: password }),
            credentials: "include",
          }).then((response) => {
            if (response.status == 403) {
              this.errors.push("Incorrect username/password.");
            } else if (response.status != 200) {
              this.errors.push(
                "An unexpected error occured during login, please try again."
              );
            }
            this.alertErrors();
            if (response.status == 200) {
              // Is there a came_from parameter to use from the query string?
              let keyValueString, keyValueIndex, keyValueArray, newLocation;
              let queryString =
                location.search && location.search.length
                  ? location.search.slice(1).split("&")
                  : "";

              for (keyValueIndex in queryString) {
                keyValueString = queryString[keyValueIndex];
                if (!keyValueString) {
                  continue;
                }

                keyValueArray = keyValueString.split("=");
                if (
                  keyValueArray &&
                  keyValueArray.length &&
                  keyValueArray[0] &&
                  keyValueArray[0] == "came_from" &&
                  keyValueArray[1]
                ) {
                  // We found a came_from parameter. Redirect to that.
                  newLocation = decodeURIComponent(keyValueArray[1]);
                  window.location = newLocation;
                  return;
                }
              }

              // If we're here, we didn't find a came_from parameter in the query string.
              // Redirect to the pre-configured location.
              window.location = CONFIG.redirect_to;
            }
          });
        } catch (e) {
          this.errors.push(
            "An error occured trying to connect to the server, check your connection and try again."
          );
          this.alertErrors(); // Run after async
        }
      } else {
        this.errors.push("Please provide an Email and Password.");
        this.alertErrors();
      }
      console.log(this.errors);
    };
  }

  /**
   * @name bindForgot
   * @description Binds a password reset function to the button.
   * @param {DOMButton} element - The element to bind onclick to
   */
  bindForgot(element) {
    element.onclick = () => {
      this.errors = [];
      this.messages = [];
      var username = this.elements.forgot.email.value.trim();
      let checks = [username.length > 0];

      if (checks.indexOf(false) === -1) {
        try {
          fetch(
            CONFIG.pwreset_micro_url + "/pwreset/api/password-reset-requests",
            {
              method: "POST",
              mode: "cors",
              body: JSON.stringify({
                username: username,
                message_body_url:
                  window.location.protocol +
                  "//" +
                  window.location.hostname +
                  CONFIG.password_reset_message,
              }),
            }
          ).then((response) => {
            if (response.status == 201) {
              this.messages.push(
                "A password reset request was sent to " +
                  username +
                  " please check your email and follow the instructions"
              );
            } else {
              this.errors.push(
                "Failed to create a reset request, try again later."
              );
            }
            console.log(this.errors);
            this.alertErrors();
          });
        } catch (e) {
          this.errors.append(
            "An error occured trying to connect to the server, check your connection and try again."
          );
          this.alertErrors();
        }
      } else {
        this.errors.push("Please provide an Email Address.");
        this.alertErrors();
      }
    };
  }

  /**
   * @name bindReset
   * @description Binds a password reset function to the button.
   * @param {DOMButton} element - The element to bind onclick to
   */
  bindReset(element) {
    element.onclick = () => {
      this.errors = [];
      this.messages = [];

      var hash = this.elements.forgot.hash.value.trim();
      var password = this.elements.forgot.password.value;
      let checks = [hash.length > 0, password.length > 0];
      if (checks.indexOf(false) === -1) {
        if (hash.length != 36)
          this.errors.push(
            "The verification code is not the correct length, check your email and copy it again."
          );
        if (zxcvbn(password).score == 0)
          this.errors.push("Choose a stronger password.");
        if (password !== this.elements.forgot.password_check.value)
          this.errors.push("Passwords do not match.");
        if (this.errors.length > 0) {
          console.log("Errors", this.errors);
          this.alertErrors();
          return;
        }

        try {
          fetch(
            CONFIG.pwreset_micro_url +
              "/pwreset/api/password-reset-requests/" +
              hash +
              "/password",
            {
              method: "POST",
              mode: "cors",
              body: JSON.stringify({
                password: password,
              }),
            }
          ).then((response) => {
            if (response.status === 422) {
              this.errors.push(
                "The proposed password is too weak. Please make it stronger and try again."
              );
              console.log(this.errors);
              this.alertErrors();
            } else if (response.status === 404) {
              this.errors.push("The reset token is no longer valid.");
              console.log(this.errors);
              this.alertErrors();
            } else if (response.status > 299) {
              this.errors.push("Failed to reset password, try again later.");
              console.log(this.errors);
              this.alertErrors();
            } else {
              this.messages.push("Password reset successful");
              location.hash = "#";
            }
          });
        } catch (e) {
          this.errors.append(
            "An error occured trying to connect to the server, check your connection and try again."
          );
          this.alertErrors();
        }
      } else {
        this.errors.push(
          "Please provide a verification code, and a strong password."
        );
        this.alertErrors();
      }
    };
  }

  /**
   * @name setChangeHandlers
   * @description Configures bindings for keypress and onchange events on each
   * of the fields in the set.
   * @param {element} element - Element we're going to bind functions to.
   * @param {string} category - Name of the category who the field belongs to.
   * @param {string} field - Name of the field who's data we're binding.
   */
  setChangeHandlers(element, category) {
    // Change values when the field accepts keypresses
    element.onkeydown = (e) => {
      if (e.keyCode === 13) {
        this.buttons[category].click();
      }
    };
  }

  /**
   * @name alertErrors
   * @description Builds an error string and shows it on the screen, and ensures
   * that the error class has been set on the window.
   */
  alertErrors() {
    let windowDiv = document.getElementById("window");
    let errorDivs = document.getElementsByClassName("errors");
    let classes = windowDiv.className.split(" ");
    // Add or remove error class & message
    if (this.errors.length > 0) {
      console.log(this.errors);
      if (classes.indexOf("error") === -1) {
        classes.push("error");
      }

      for (let i = 0; i < errorDivs.length; i++) {
        let errorDiv = errorDivs[i];
        errorDiv.innerHTML = this.errors.join(",");
      }
    } else {
      if (classes.indexOf("error") > -1) {
        classes.splice(classes.indexOf("error"), 1);
      }
      for (let i = 0; i < errorDivs.length; i++) {
        let errorDiv = errorDivs[i];
        errorDiv.innerHTML = this.messages.join(",");
      }
    }

    // Recompile class list
    windowDiv.className = classes.join(" ");
  }

  /**
   * @name clearErrors
   * @description Convenience function for clearing up the errors and removing
   * error messages from the UI.
   */
  clearErrors() {
    this.errors = [];
    this.alertErrors();
  }

  /**
   * @name initForm
   * @description allows some fields to be prefilled by arguments passed to the route
   */
  initForm(form, routeData) {
    for (let pair of routeData) {
      let k = pair.split("=")[0];
      let v = pair.split("=")[1];
      this.elements[form][k].value = v;
      this.values[form][k] = v;
    }
  }
}

/***
 * @className FormManager
 * @description Manages the display of different types of forms based on the
 * window's hash.
 */
export default class FormManager {
  /**
   * @name constructor
   * @description Initializes a new FormManager object
   */
  constructor() {
    window.onhashchange = () => this.loadHash();

    this.auth = new AuthConnector();
    this.loadHash();
    const currentDate = new Date().getFullYear();
    document.getElementById("copyright-year").innerText = currentDate;
  }

  /**
   * @name loadHash
   * @description Loads the correct form & buttons for the specified location
   * hash string.
   */
  loadHash() {
    // Reset errors on Auth
    this.auth.clearErrors();

    // Figure out target location
    let route_data = window.location.hash.replace("#", "").split("?");
    let form = route_data.length === 0 ? "login" : route_data[0] || "login";
    let fieldSets = document.querySelectorAll(".fieldset");

    // Set visibility of forms
    for (let key in Object.keys(fieldSets)) {
      if (fieldSets[key].className.indexOf(form) > -1) {
        fieldSets[key].style.display = "flex";
        this.auth.initForm(form, route_data[1] ? route_data[1].split("&") : []);
      } else {
        fieldSets[key].style.display = "none";
      }
    }
  }
}

new FormManager();
